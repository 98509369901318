<template>
  <div class="va-typography-block">
    <slot />
  </div>
</template>

<script>
// TODO:(1.5.0) Remove this component
export default {
  name: 'VaContent',
}
</script>
